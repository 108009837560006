import { RouteComponentProps } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { Page } from '../../components/Page';
import { NetworkPicker } from './NetworkPicker';
import { Title } from '../../components/Title';
import { withRoot } from '../../components/Root';
import { useFragmentContext } from '../../../shared/helpers/useFragmentContext';
import { FragmentContext } from '../../../shared/types/fragmentContext';
import WiflixLogo from '../../images/wi-flix.png';

const Wrapper = styled('section')(() => ({
    flex: 1,
    padding: 0,
    '& form': {
        flex: 1,
    },
}));

const Logo = styled('img')(() => ({
    width: 80,
    height: 'auto',
}));

const SelectNetworkComponent = (props: RouteComponentProps<never>): JSX.Element => {
    const { history, location } = props;
    const search = new URLSearchParams(location.search);

    const onSelectNetwork = (network: string, countryCode: string): void => {
        search.set('nw', network);
        search.set('country', countryCode);

        history.replace({
            pathname: '/',
            search: search.toString(),
        });
    };

    const { countryCode } = useFragmentContext<FragmentContext>();

    return (
        <Page>
            <Wrapper>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        marginBottom: 2,
                    }}
                >
                    <Logo src={WiflixLogo} />
                </Box>
                <Title>Select your mobile network</Title>
                <NetworkPicker onSelectNetwork={onSelectNetwork} countryCode={countryCode} />
            </Wrapper>
        </Page>
    );
};

SelectNetworkComponent.getInitialProps = () => {
    // get initial component props
};

SelectNetworkComponent.getChunkName = () => {
    return 'SelectNetwork';
};

const SelectNetwork = withRoot(SelectNetworkComponent);

export { SelectNetwork };
