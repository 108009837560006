import { Box, FormControlLabel, styled } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { matchPath, RouteComponentProps } from 'react-router-dom';

import { Network } from '../../../shared/enums/network';
import { useFragmentContext } from '../../../shared/helpers/useFragmentContext';
import { FragmentContext } from '../../../shared/types/fragmentContext';
import { UserCredentials } from '../../../shared/types/userCredentials';
import { Checkbox } from '../../components/Checkbox';
import { ControlBlock } from '../../components/ControlBlock';
import { ErrorText } from '../../components/ErrorText';
import { FormActions } from '../../components/FormActions/FormActions';
import { Input } from '../../components/Input';
import { Label } from '../../components/Label';
import { Page } from '../../components/Page';
import { PhoneNumberInput } from '../../components/PhoneNumberInput';
import { withRoot } from '../../components/Root';
import { SubmitButton } from '../../components/SubmitButton';
import { Subtitle } from '../../components/Subtitle';
import { Title } from '../../components/Title';
import { WeightyLink } from '../../components/WeightyLink';
import { getUserCredentialsFromProps } from '../../helpers/getUserCredentialsFromProps';
import { recordPageView } from '../../services/recordPageView';
import { registerUser } from '../../services/registerUser';
import { getEncryptedMsisdn } from './getEncryptedMsisdn';
import { getInitialProps } from './getInitialProps';
import { MatchParams } from './types/matchParams';
import { RegisterProps } from './types/registerProps';
import { getValidationSchema } from './validationSchema';

import WiflixLogo from '../../images/wi-flix.png';

const IntegratedCountries = ['NG', 'GH', 'ZM', 'KE'];

const SignupMeta = styled('fieldset')(() => ({
    textAlign: 'center',
    marginTop: 24,
}));


const Logo = styled('img')(() => ({
    width: 140,
    height: 'auto'
}))

function Register(props: RegisterProps & RouteComponentProps<MatchParams>): JSX.Element {
    const { location, network, history, fullName, email, phoneNumber } = props;
    const { countryCode } = useFragmentContext<FragmentContext>();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');

    const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(true);
    const hasCountrySupport = countryCode
        ? IntegratedCountries.map(entry => entry.toLowerCase()).indexOf(countryCode.toLowerCase()) >
        -1
        : false;

    const search: URLSearchParams = useMemo(() => new URLSearchParams(location.search), [location]);
    const userNetwork = search.get('nw') || network;
    const hasUnsupportedNetwork = userNetwork?.toString().toLowerCase() === 'other';

    const [submitError, setSubmitError] = useState('');

    const match = matchPath<MatchParams>(location.pathname, props);
    const [pageViewRecorded, setPageViewRecorded] = useState(false);
    const checkoutURL = search.toString()
        ? `https://checkout.wi-flix.com/?${search.toString()}`
        : 'https://checkout.wi-flix.com';

    useEffect(() => {
        if (match?.params?.affiliate && !pageViewRecorded) {
            (async (affiliate: string) => {
                try {
                    await recordPageView(affiliate);
                    setPageViewRecorded(true);
                } catch (e) {
                    console.error(e);
                }
            })(match.params.affiliate);
        }
    }, [match, pageViewRecorded]);

    useEffect(() => {
        if (hasCountrySupport && !userNetwork) {
            history.push({
                pathname: '/select-network',
                search: search.toString(),
            });
        }
    }, [countryCode, history, userNetwork, hasCountrySupport, search]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const recaptchaToken = await executeRecaptcha('register');
        setToken(recaptchaToken);
    }, [executeRecaptcha]);

    useEffect(() => {
        (async () => {
            await handleReCaptchaVerify();
        })();
    }, [handleReCaptchaVerify]);

    const emailRequired = !hasCountrySupport || hasUnsupportedNetwork;

    const { errors, values, handleChange, handleSubmit, isSubmitting } = useFormik({
        initialValues: getUserCredentialsFromProps({
            fullName,
            email,
            phoneNumber,
            network: userNetwork || Network.OTHER,
        }),
        validateOnChange: false,
        validationSchema: getValidationSchema(emailRequired),
        onSubmit: async (credentials: UserCredentials, { setSubmitting }) => {
            try {
                setSubmitting(true);
                let encryptedMsisdn: string;
                if (userNetwork?.toLowerCase() === 'safaricom') {
                    encryptedMsisdn = await getEncryptedMsisdn();
                    // eslint-disable-next-line no-param-reassign
                    credentials.encryptedMsisdn = encryptedMsisdn;
                }

                const { target, userId } = await registerUser(credentials, token, search);

                /**
                 * trackEvent is injected by the boostmate script (universal.js)
                 * 
                 */
                if ((window as any).trackEvent) {
                    (window as any).trackEvent("signup", {
                        customerKey: `${userId}_onboarding`
                    });
                } else {
                    console.error("Boostmate tracking function not available");
                }

                window.open(target, '_self');

            } catch (e) {
                if (e instanceof Error) {
                    setSubmitError(e.message);
                    return;
                }

                console.error(e);

                setSubmitError('Something went wrong. Please try again later.');
            }
        },
    });

    const onChange = (name: string, value: string): void => {
        handleChange({ target: { name, value } });
    };

    if (!countryCode) {
        // TODO: return loading page
        return <></>;
    }

    if (hasCountrySupport && !userNetwork) {
        // TODO: return loading page
        return <></>;
    }

    return (
        <Page>
            <Box
                sx={{
                    flex: 1,
                    '& form': {
                        flex: 1,
                    },
                }}
            >
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Logo src={WiflixLogo} />
                    </Box>

                    <Title variant="h3">Sign up</Title>
                    {emailRequired && (
                        <>
                            <ControlBlock>
                                <Label value="Your Email ID" />

                                <Input
                                    variant="outlined"
                                    name="email"
                                    value={values.email || ''}
                                    placeholder="Email"
                                    type="email"
                                    autoComplete="off"
                                    fullWidth
                                    error={errors.email}
                                    onChange={onChange}
                                />
                            </ControlBlock>

                            <ControlBlock>
                                <Label value="Your Full Name" />
                                <Input
                                    variant="outlined"
                                    name="fullName"
                                    value={values.fullName || ''}
                                    placeholder="Enter your full name"
                                    type="text"
                                    autoComplete="off"
                                    fullWidth
                                    error={errors.fullName}
                                    onChange={onChange}
                                />
                            </ControlBlock>
                        </>
                    )}

                    <ControlBlock>
                        <Label value="Your Phone Number" />
                        <PhoneNumberInput
                            name="phoneNumber"
                            error={errors.phoneNumber}
                            value={values.phoneNumber}
                            placeholder="Enter your phone number"
                            onChange={value => onChange('phoneNumber', value)}
                            disabled={Boolean(phoneNumber)}
                            defaultCountry={countryCode}
                        />
                    </ControlBlock>

                    <ControlBlock>
                        <Label value="Password" />
                        <Input
                            variant="outlined"
                            name="password"
                            value={values.password}
                            placeholder="Password"
                            type="password"
                            autoComplete="new-password"
                            fullWidth
                            error={errors.password}
                            onChange={onChange}
                        />
                    </ControlBlock>

                    <ControlBlock>
                        <Label value="Confirm Password" />
                        <Input
                            variant="outlined"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            placeholder="Repeat Password"
                            type="password"
                            autoComplete="new-password"
                            fullWidth
                            onChange={onChange}
                            error={errors.confirmPassword}
                        />
                    </ControlBlock>

                    <SignupMeta>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptTermsOfUse}
                                    onChange={() => setAcceptTermsOfUse(!acceptTermsOfUse)}
                                    name="terms-of-use"
                                />
                            }
                            label={
                                <Subtitle>
                                    By continuing, you agree to our
                                    <WeightyLink
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open('/terms-and-conditions', '_blank')
                                        }
                                    >
                                        Terms of Service
                                    </WeightyLink>{' '}
                                    &nbsp;and&nbsp;
                                    <WeightyLink
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => window.open('/privacy-policy', '_blank')}
                                    >
                                        Privacy Policy
                                    </WeightyLink>
                                </Subtitle>
                            }
                        />
                    </SignupMeta>

                    <FormActions>
                        <SubmitButton
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            fullWidth
                            variant="contained"
                            type="submit"
                        >
                            Sign Up
                        </SubmitButton>
                    </FormActions>

                    {submitError && <ErrorText error>{submitError}</ErrorText>}

                    <SignupMeta>
                        <Subtitle
                            style={{
                                color: '#868788',
                                paddingLeft: 8,
                            }}
                            variant="subtitle2"
                        >
                            Already Registered?
                            <WeightyLink style={{ color: '#ff6c2f' }} href={checkoutURL}>
                                Sign In
                            </WeightyLink>
                        </Subtitle>
                    </SignupMeta>
                </form>
            </Box>
        </Page>
    );
}

Register.getInitialProps = getInitialProps;
Register.getChunkName = () => {
    return 'Register';
};

const RegisterRoute = withRoot(Register);

export { RegisterRoute };
